var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoad
    ? _c(
        "div",
        { staticClass: "taobao_container" },
        [
          _c("Loading", { attrs: { visible: _vm.loading } }),
          _c("div", { staticClass: "detail-wrapper" }, [
            _c(
              "div",
              { staticClass: "left-container", attrs: { "bi-route": "left" } },
              [
                _c("div", { staticClass: "detail-row" }, [
                  _c("div", { staticStyle: { display: "flex" } }, [
                    _c("div", { staticClass: "img-block" }, [
                      _c("span", { staticClass: "img" }, [
                        _c("img", {
                          staticClass: "lg-img",
                          attrs: { src: _vm.imgLargeUrl, alt: "" }
                        }),
                        _vm.images.length > 0
                          ? _c(
                              "ul",
                              { staticClass: "sm-img" },
                              _vm._l(_vm.imagess, function(item, index) {
                                return _c(
                                  "li",
                                  {
                                    key: index,
                                    on: {
                                      mouseenter: function($event) {
                                        return _vm.mousemove(item)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.openUrl,
                                          target: "_blank"
                                        }
                                      },
                                      [
                                        _c("img", {
                                          class:
                                            _vm.imgLargeUrl === item
                                              ? "img_hover"
                                              : "",
                                          attrs: { src: item, alt: "" }
                                        })
                                      ]
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e()
                      ])
                    ]),
                    _c("div", { staticClass: "detail-col" }, [
                      _c(
                        "a",
                        { attrs: { href: _vm.openUrl, target: "_blank" } },
                        [
                          _c("span", { staticClass: "title" }, [
                            _c("span", { staticClass: "logo" }),
                            _c("span", { staticClass: "title" }, [
                              _vm._v(_vm._s(_vm.goodsData.goodsName))
                            ])
                          ])
                        ]
                      ),
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _vm.goodsData.couponInfo
                          ? _c("div", { staticClass: "coupon" }, [
                              _c("span", { staticClass: "quan" }, [
                                _vm._v(
                                  _vm._s(
                                    parseFloat(_vm.goodsData.couponInfo.fav)
                                  ) + "元券"
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm.goodsData.discount &&
                        _vm.goodsData.discount > 0 &&
                        _vm.goodsData.discount < 1
                          ? _c("div", { staticClass: "discount" }, [
                              _c("span", { staticClass: "quan" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      (_vm.goodsData.discount * 10 + "").slice(
                                        0,
                                        3
                                      )
                                    ) +
                                    "折 "
                                )
                              ])
                            ])
                          : _vm._e()
                      ]),
                      _c("div", { staticClass: "price_block" }, [
                        _c("div", { staticClass: "fla" }, [
                          _c("div", { staticClass: "price" }, [
                            _vm._v(" ￥ "),
                            _c("i", [_vm._v(_vm._s(_vm.goodsData.vipPrice))]),
                            _c("b", [
                              _vm._v(" ￥"),
                              _c("i", [
                                _vm._v(_vm._s(_vm.goodsData.marketPrice))
                              ])
                            ])
                          ]),
                          _vm.goodsData.commentsInfo
                            ? _c("div", { staticClass: "fr sales" }, [
                                _vm._v(
                                  " 已售 " +
                                    _vm._s(
                                      _vm._f("salesNum")(
                                        _vm.goodsData.commentsInfo.comments
                                      )
                                    ) +
                                    " 件 "
                                )
                              ])
                            : _vm._e()
                        ]),
                        _c("div", { staticClass: "buy-share" }, [
                          _c("div", { staticClass: "share-btn" }, [
                            _c(
                              "a",
                              {
                                staticClass: "buy",
                                attrs: { href: _vm.openUrl, target: "_blank" }
                              },
                              [_vm._v("领券购买")]
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "buy share_qrcode",
                                on: { mouseenter: _vm.handleCode }
                              },
                              [
                                _c("i"),
                                _vm._v(" 扫码购买 "),
                                _c("div", { staticClass: "qrcode" }, [
                                  _vm.isShow
                                    ? _c("img", {
                                        staticClass: "loading",
                                        attrs: {
                                          src: require("../../assets/detail/loading.png"),
                                          alt: ""
                                        }
                                      })
                                    : _vm._e(),
                                  _c("span", { attrs: { id: "qrcode" } }),
                                  _c("span", [_vm._v("手机扫码")])
                                ])
                              ]
                            )
                          ])
                        ])
                      ])
                    ])
                  ])
                ]),
                _vm.detailPics && _vm.detailPics.length > 0
                  ? _c(
                      "div",
                      { staticClass: "goods-detail" },
                      [
                        _vm._m(0),
                        _vm._l(_vm.detailPics, function(item, index) {
                          return _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: item,
                                expression: "item"
                              }
                            ],
                            key: index,
                            attrs: { src: item, alt: "" }
                          })
                        })
                      ],
                      2
                    )
                  : _vm._e()
              ]
            ),
            _c(
              "div",
              { staticClass: "right-container" },
              [
                _c("hot-sales", {
                  attrs: {
                    items: _vm.todayItmes,
                    fun: _vm.getDetailData,
                    funLike: _vm.getLikeData,
                    type: "wph"
                  }
                })
              ],
              1
            )
          ]),
          _c("statement", { attrs: { items: _vm.goodsId } })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tit" }, [_c("span", [_vm._v("商品详情")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }