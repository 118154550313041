<template>
  <div class="taobao_container" v-if="isLoad">
    <Loading :visible="loading" />
    <!-- <div class="nav-wrap">
      <div class="text">
        <a href="">首页</a> <i></i> <a href="">领券直播</a> <i></i>
        <a href="">咚咚抢</a> <i></i> <a href="">超级人气榜</a> <i></i>
        <a href="">9块9包邮</a> <i></i> <a href="">折上折</a> <i></i>
        <span>优惠详情</span>
      </div>
    </div> -->
    <div class="detail-wrapper">
      <div class="left-container" bi-route="left">
        <div class="detail-row">
          <div style="display: flex">
            <div class="img-block">
              <span class="img">
                <img class="lg-img" :src="imgLargeUrl" alt="" />
                <ul class="sm-img" v-if="images.length > 0">
                  <li
                    v-for="(item, index) in imagess"
                    :key="index"
                    @mouseenter="mousemove(item)"
                  >
                    <a :href="openUrl" target="_blank">
                      <img
                        :src="item"
                        :class="imgLargeUrl === item ? 'img_hover' : ''"
                        alt=""
                      />
                    </a>
                  </li>
                </ul>
              </span>
            </div>
            <div class="detail-col">
              <a :href="openUrl" target="_blank">
                <span class="title">
                  <span class="logo"></span>
                  <!-- <span class="zy" v-if="goodsData.isOwner === 1"></span> -->
                  <span class="title">{{ goodsData.goodsName }}</span>
                </span>
              </a>
              <div style="display: flex">
                <div class="coupon" v-if="goodsData.couponInfo">
                  <span class="quan"
                    >{{ parseFloat(goodsData.couponInfo.fav) }}元券</span
                  >
                </div>
                <div
                  class="discount"
                  v-if="
                    goodsData.discount &&
                    goodsData.discount > 0 &&
                    goodsData.discount < 1
                  "
                >
                  <span class="quan">
                    {{ (goodsData.discount * 10 + "").slice(0, 3) }}折
                  </span>
                </div>
              </div>
              <div class="price_block">
                <div class="fla">
                  <div class="price">
                    ￥ <i>{{ goodsData.vipPrice }}</i>
                    <b>
                      ￥<i>{{ goodsData.marketPrice }}</i>
                    </b>
                  </div>
                  <div class="fr sales" v-if="goodsData.commentsInfo">
                    已售 {{ goodsData.commentsInfo.comments | salesNum }} 件
                  </div>
                </div>
                <div class="buy-share">
                  <div class="share-btn">
                    <!-- <span @click="handleBuy" class="buy">领券购买</span> -->
                    <a :href="openUrl" class="buy" target="_blank">领券购买</a>
                    <span class="buy share_qrcode" @mouseenter="handleCode">
                      <i></i>
                      扫码购买
                      <div class="qrcode">
                        <img
                          src="../../assets/detail/loading.png"
                          class="loading"
                          v-if="isShow"
                          alt=""
                        />
                        <span id="qrcode"></span>
                        <span>手机扫码</span>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="goods-detail" v-if="detailPics && detailPics.length > 0">
          <div class="tit"><span>商品详情</span></div>
          <img
            :src="item"
            alt=""
            v-for="(item, index) in detailPics"
            :key="index"
            v-lazy="item"
          />
        </div>
      </div>
      <div class="right-container">
        <hot-sales
          :items="todayItmes"
          :fun="getDetailData"
          :funLike="getLikeData"
          type="wph"
        />
      </div>
    </div>
    <statement :items="goodsId" />
  </div>
</template>

<script>
import HotSales from "@/components/HotSales";
import IconFont from "@/components/IconFont.vue";
import Statement from "@/components/Statement";
import Loading from "@/components/loading";
import { Format } from "@/utils/timeFormat";
import { mapGetters } from "vuex";
import { importCDN } from "@/utils/utils";
export default {
  components: {
    IconFont,
    HotSales,
    Statement,
    Loading,
  },
  computed: {
    getTime() {
      return Format(new Date(this.goodsData.createTime), "MM-dd hh:mm");
    },
    ...mapGetters(["marketGroup", "config"]),
  },
  data() {
    return {
      imgLargeUrl: "",
      nowTime: "",
      ShortLink: "",
      loading: true,
      Link: "",
      goodsData: {},
      id: 0,
      goodsId: "",
      newTime: "",
      images: [],
      likeItems: [],
      todayItmes: [],
      detailPics: [],
      jhsEndTime: 0,
      imgs: [],
      isShow: true,
      hours: 0,
      min: 0,
      sec: 0,
      now: "",
      end: "",
      next: true,
      nextTwo: true,
      openUrl: "",
      isLoad: false,
    };
  },
  mounted() {
    if(!this.$route.query.id){
      document.body.style.opacity = "1";
      this.$router.replace("/index/notfound");
    }
    this.nowTime = new Date().getTime();
    importCDN("https://public.ffquan.cn/lib/qrcode.js").then(()=>{
      setTimeout(() => {
        this.getDetailData();
      }, 1000);  
    });
   
    this.getTodayData();
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  methods: {
    async handleBuy(e) {
      if (!this.nextTwo) {
        return;
      }
      this.nextTwo = false;
      if (this.ShortLink || this.Link) {
        this.handleBiEvent(e);
        this.timer = setTimeout(() => {
          this.nextTwo = true;
          window.open(this.ShortLink || this.Link);
        }, 500);
        return;
      }
      const res = await this.transformLink(function () {}, e);
      if (res && res.code == 1) {
        this.nextTwo = true;
        // let data = res.data.urlInfoList[0];
        // window.open(data.noEvokeUrl || data.noEvokeLongUrl);
      }
    },
    handleJumpPage() {
      // 判断是否配置显示详情页
      if (this.config.detail_switch == 1) {
        document.body.style.opacity = "1";
        this.isLoad = true;
        this.setConfig();
      } else {
        this.isLoad = false;
        this.transformLink((res) => {
          if (res && res.code == 1) {
            let data = res.data.urlInfoList[0];
            window.location.href = data.noEvokeUrl || data.noEvokeLongUrl;
          } else {
            this.$router.push("/index/notfound");
            document.body.style.opacity = "1";
          }
        });
      }
    },
    handleCode(e) {
      if (this.ShortLink || this.Link) {
        this.handleBiEvent(e);
        let str = `qrcode`;
        let dom = document.getElementById(str);
        if(!dom) return;
        if (!dom.children.length) {
          new QRCode(dom, {
            text: this.ShortLink,
            height: 100,
            width: 100,
          });
        }
        return;
      }
      this.transformLink((res) => {
        if (res.code == 1) {
          let data = res.data.urlInfoList[0];
          let str = `qrcode`;
          new QRCode(document.getElementById(str), {
            text: data.noEvokeUrl,
            height: 100,
            width: 100,
          });
        }
      }, e);
    },
    mousemove(url) {
      this.imgLargeUrl = url;
    },

    handleBiEvent(e) {
      const data = {
        c_uid: 0,
        cps_uid: 0,
        column: 0,
        source: "CMS",
        service: "C",
        pid: this.config.vip.pid || "", //pid
        auth_id: this.config.vip.auth_id, //授权id
        // materialId: this.goodsData.materialUrl,
        // unionId: this.config.vip.union_id,
        // positionId: this.config.vip.position_id,
        // couponUrl: this.goodsData.couponLink,
      };
      if (e) {
        try {
          this.$BIJsdk.onEvent({
            event: e.target,
            extra: {
              pingtai: "wph",
              ...data,
            },
            event_type: e.type,
          });
        } catch (error) {}
      }
    },
    // 转链
    async transformLink(callback, e) {
      if (!this.next) {
        return;
      }
      if (!this.goodsData.destUrl) {
        return this.transformLink(callback);
      }
      this.next = false;
      if (this.ShortLink || this.Link) {
        window.location.href = this.ShortLink || this.Link;
        return;
      }
      const res = await this.$services.getVipLink({
        urlList: JSON.stringify([this.goodsData.destUrl]),
        authId: this.config.vip.auth_id,
        chanTag: this.config.vip.pid,
        uid: this.config.site_id,
        // siteId:this.config.vip.uid,
        // realCall:true,
        urlGenRequest:`{"openId":"dtk_${this.config.vip.uid}","adCode":"${this.$route.query.adCode}","realCall":true}`,
        // adCode:this.$route.query.adCode
        // chanTag:this.config.vip.pid
      });
      if (res.code == 1) {
        let resData = res.data.urlInfoList[0];
        this.ShortLink = resData.noEvokeUrl;
        this.Link = resData.noEvokeLongUrl;
        this.openUrl = resData.noEvokeUrl || resData.noEvokeLongUrl;
      }
      callback && callback(res);
      this.isShow = false;
      this.next = true;
      return res;
    },
    getTodayData() {
      this.$services.getTodayData({ rankType: 1 }).then((res) => {
        if (res && res.code === 0) {
          this.todayItmes = res.data;
        }
      });
    },
    getDetailData() {
      this.goodsId = this.$route.query.id;
      this.$services
        .getWphDetails({ 
          goodsIdList: JSON.stringify([this.goodsId]) ,
          siteId:this.config.vip.uid,
          realCall:true,
          chanTag:this.config.vip.pid
        })
        .then((res) => {
          if (res && res.code === 0) {
            if(!res.data[0]){
              document.body.style.opacity = "1";
              this.$router.replace("/index/notfound");
              return;
            }
            this.goodsData = res.data[0];
            this.images = [
              ...[res.data[0].goodsMainPicture],
              ...res.data[0].goodsCarouselPictures,
            ].slice(0, 5);
            this.imagess = new Set(this.images);
            this.imgLargeUrl = res.data[0].goodsMainPicture;
            this.detailPics = res.data[0].goodsDetailPictures;
            this.id = res.data[0].id;
            this.goodsId = res.data[0].goodsId;
            document.title = `${res.data[0].goodsName}_${this.config.site_name}`;
            if (this.config.site_id && this.goodsId) {
              this.handleJumpPage();
            }
          }else{
            document.body.style.opacity = "1";
            this.$router.replace("/index/notfound");
          }
          this.loading = false;
        });
    },
    setConfig() {
      var _this = this;
      if (Object.keys(this.config).length > 0) {
        _this.handleBuy();
      }
    },
  },
  watch: {
    config() {
      document.title = `${this.goodsData.goodsName}_${this.config.site_name}`;
      this.handleJumpPage();
    },
  },
};
</script>

<style lang="less" scoped>
.taobao_container {
  width: 1200px - 2 - 23 * 2;
  margin: 0 auto;
  // border: 1px solid #f7e7e7;
  padding: 23px;
  .nav-wrap {
    margin-bottom: 20px;
    display: block;
    .text {
      color: #888;
      font-size: 12px;
      position: relative;
      i {
        color: #bcbcbc;
        display: inline-block;
        position: relative;
        top: 1px;
        border-left: 6px solid #bcbcbc;
        border-top: 6px solid #f6f6f6;
        border-bottom: 6px solid #f6f6f6;
        margin: 0 8px;
        &::before {
          content: "";
          position: absolute;
          left: -6px;
          top: -5px;
          border-left: 5px solid #f6f6f6;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
        }
      }
      a {
        padding: 0 5px;
        color: #444;
        &:hover,
        &:focus {
          color: #ff0000;
        }
      }
    }
  }
  .detail-wrapper {
    display: flex;
    width: 1200px;
    .left-container {
      width: 74.5%;
      .detail-row {
        background: #fff;
        padding: 20px 20px 40px;
        border: 1px solid #f1f1f1;
        margin-bottom: 20px;
        .img-block {
          float: left;
          .img {
            cursor: pointer;
            .sm-img {
              display: inline-block;
              margin-top: 2px;
              li {
                width: 45px;
                height: 45px;
                margin-left: 10px;
                cursor: auto;
                margin-top: 0;
                margin-bottom: 8px;
                cursor: pointer;
                img {
                  width: 45px;
                  height: 45px;
                  &:hover {
                    box-shadow: 0 0 6px 0 #ffc4cf;
                    border: 1px solid #ff2d54;
                  }
                }
                .img_hover {
                  box-shadow: 0 0 6px 0 #ffc4cf;
                  border: 1px solid #ff2d54;
                }
              }
            }
            .lg-img {
              width: 260px;
              height: 260px;
              float: left;
            }
          }
        }
        .detail-col {
          width: 58%;
          margin: -5px 0 0 38px;
          padding-bottom: 0;
          span.title {
            margin-left: 4px;
            display: flex;
            cursor: pointer;
            .logo {
              background: url("../../assets/detail/wph.png") no-repeat;
              width: 25px;
              background-size: 100% 100%;
              height: 15px;
              display: block;
              margin-top: 4px;
            }
            .zy {
              margin-left: 5px;
              background: url("../../assets/detail/zy.png") center/contain
                no-repeat;
              width: 40px;
              height: 16px;
              display: block;
              background-size: cover;
              margin-top: 4px;
            }
            span.title {
              float: left;
              width: 650px;
              height: 30px;
              overflow: hidden;
              display: block;
              font-size: 19px;
              color: #333;
              margin-left: 5px;
              overflow: hidden; // 溢出隐藏
              text-overflow: ellipsis; // 显示省略号
              white-space: nowrap; // 不换行
              &:hover {
                color: #666;
              }
            }
          }
          .active-banners {
            width: 100%;
            height: 38px;
            position: relative;
            .act-bg {
              background: url("../../assets/detail/act-bg.png") 100% 100%;
              position: absolute;
              left: -15px;
              bottom: 0;
              width: 542px;
              height: 38px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              background-size: cover;
              .act-text {
                display: flex;
                align-items: center;
                float: right;
                line-height: 30px;
                color: #fff;
                font-size: 13px;
                margin: 8px 30px 0 0;
                .rank-list {
                  width: 134px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  background: rgba(255, 255, 255, 0.87);
                  border-radius: 10px;
                  margin-left: 10px;
                  color: red;
                  line-height: 20px;
                  padding: 0 8px;
                  box-sizing: border-box;
                }
                .hot-ico {
                  background: url("../../assets/detail/hot.png") center
                    no-repeat;
                  background-size: 100% 100%;
                  width: 11px;
                  height: 13px;
                  display: inline-block;
                  margin-right: 2px;
                  margin-bottom: -2px;
                }
              }
            }
          }
          .jhs-banners {
            width: 100%;
            height: 38px;
            position: relative;
            .act-bg {
              background: url("../../assets/detail/jhs-bg.png") 100% 100%;
              position: absolute;
              left: -15px;
              bottom: 0;
              width: 542px;
              height: 38px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              background-size: cover;
              .act-text {
                display: flex;
                align-items: center;
                float: right;
                line-height: 30px;
                color: #fff;
                font-size: 13px;
                margin: 8px 30px 0 0;
                .rank-list {
                  width: 134px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  background: rgba(255, 255, 255, 0.87);
                  border-radius: 10px;
                  margin-left: 10px;
                  color: red;
                  line-height: 20px;
                  padding: 0 8px;
                  box-sizing: border-box;
                }
                .hot-ico {
                  background: url("../../assets/detail/hot.png") center
                    no-repeat;
                  background-size: 100% 100%;
                  width: 11px;
                  height: 13px;
                  display: inline-block;
                  margin-right: 2px;
                  margin-bottom: -2px;
                }
              }
            }
          }
          .coupon {
            margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 59px;
            height: 22px;
            color: #fff;
            background-image: linear-gradient(180deg, #f65d49 0%, #fe2e54 100%);
            border-radius: 2px;
            margin-right: 10px;
          }
          .discount {
            margin-top: 20px;
            height: 22px;
            line-height: 22px;
            padding: 0 5px;
            font-size: 14px;
            color: #ff6600;
            background: #ffece0;
            border-radius: 2px;
          }
          .time-num {
            margin: 20px 0 10px;
            font-size: 12px;
            .time {
              margin-right: 105px;
            }
            .num {
              span {
                color: #ff2e54;
              }
            }
          }
          .goods-label {
            border-top: 1px solid #ececec;
            padding: 15px 0 0;
            margin: 15px 0 5px;
            font-size: 12px;
            .label {
              background: #f5f5f5;
              border-radius: 12px;
              border: 1px solid #ddd;
              padding: 3px 8px;
              margin: 0 10px 10px 0;
              display: inline-block;
            }
          }
          .price_block {
            border-top: 1px solid #ececec;
            padding-top: 20px;
            margin-top: 20px;
            .fla {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .price {
                font-size: 22px;
                color: #ff0100;
                margin: 0 0 10px;
                i {
                  font-style: normal;
                }
                b {
                  font-size: 12px;
                  font-weight: 400;
                  display: inline-block;
                  color: #666;
                  line-height: 0;
                  border-top: 1px solid #666;
                  margin-left: 10px;
                  position: relative;
                  top: -2px;
                  i {
                    font-style: normal;
                  }
                }
              }
            }

            .buy-share {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .share-btn {
                display: flex;
                align-items: center;
                .buy {
                  width: 120px;
                  height: 36px;
                  line-height: 36px;
                  text-align: center;
                  background: #ff2e54;
                  border-radius: 2px;
                  display: inline-block;
                  color: #fff;
                  font-size: 14px;
                  margin-right: 13px;
                  cursor: pointer;
                  &:hover {
                    background: #e31e43;
                  }
                }
                .share_qrcode {
                  position: relative;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background: #fff;
                  color: #ff5b00;
                  border: 1px solid #ff5b00;
                  cursor: pointer;
                  &:hover {
                    background: #f9f9f9;
                  }
                  i {
                    background: url("../../assets/detail/qrcode_ico.png") center
                      no-repeat;
                    background-size: 100% 100%;
                    width: 16px;
                    height: 16px;
                    display: inline-block;
                    margin-right: 5px;
                  }
                  .qrcode {
                    display: none;
                    line-height: 20px;
                    padding: 5px 0 10px;
                    span {
                      margin-top: 10px;
                    }
                    .loading {
                      width: 30px;
                      height: 30px;
                      animation: loading 1s linear infinite;
                    }
                    @keyframes loading {
                      100% {
                        transform: rotate(360deg);
                      }
                    }
                  }
                  &:hover .qrcode {
                    position: absolute;
                    top: 47px;
                    left: 0;
                    width: 120px;
                    background: #fff;
                    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 20%);
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    padding-top: 10px;
                  }
                }
              }
            }
          }
        }
        .goods-desc {
          padding: 20px 0 0;
          .tit {
            font-weight: 700;
            color: #333;
            height: 40px;
            line-height: 40px;
            border-bottom: 1px solid #ececec;
          }
          .rec-text {
            color: #555;
            line-height: 24px;
            margin: 10px 0 15px;
          }
          .content {
            p {
              a {
                color: #42a0ee;
              }
            }
          }
          .feedback {
            font-size: 12px;
            margin-top: 30px;
            span {
              background: #eee;
              color: #666;
              padding: 3px 10px;
              margin-right: 7px;
              cursor: pointer;
              &:hover {
                color: #ff2e54;
              }
            }
            a {
              background: #eee;
              color: #666;
              padding: 3px 10px;
              margin-right: 7px;
              cursor: pointer;
              &:hover {
                color: #ff2e54;
              }
            }
          }
        }
      }
      .shop-msg {
        width: 100%;
        height: 145px;
        border: 1px solid #f1f1f1;
        background: #fff;
        .tit {
          font-size: 18px;
          height: 44px;
          line-height: 44px;
          background: #fff;
          border-bottom: 1px solid #f1f1f1;
          padding: 0 10px;
          box-sizing: border-box;
          span {
            border-left: 3px solid #ff2e54;
            padding: 2px 0 0 10px;
          }
        }
        .shop-block {
          height: 100px;
          padding: 20px;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          align-items: center;
          div {
            width: 48%;
            display: flex;
            align-items: center;
            .shop-name {
              margin-left: 15px;
              display: initial;
              .iconfont {
                font-size: 12px;
                margin-top: 10px;
                color: #d81e06;
              }
              .icon-detail_tb {
                display: block;
                font-size: 12px;
                margin-top: 10px;
                color: #fc6421;
              }
            }
          }
          .shop-score {
            padding: 5px 0;
            box-sizing: border-box;
            display: flex;
            li {
              width: 100px;
              // float: left;
              font-size: 12px;
              color: #999;
              text-align: center;
              span {
                margin-top: 10px;
                display: block;
                font-size: 16px;
                color: #555;
                .icon {
                  position: relative;
                  top: -1px;
                  text-align: center;
                  font-family: "Microsoft YaHei";
                  border-radius: 2px;
                }
                .high {
                  width: 16px;
                  height: 16px;
                  font-style: initial;
                  line-height: 17px;
                  display: inline-block;
                  font-size: 12px;
                  color: #fff;
                  background: #e31436;
                }
              }
            }
          }
        }
      }
      .goods-detail {
        width: 100%;
        margin-top: 20px;
        border: 1px solid #f1f1f1;
        background: #fff;
        padding: 0 20px 20px;
        box-sizing: border-box;
        img {
          width: 100%;
          margin-top: -5px;
        }
        .tit {
          font-size: 18px;
          height: 44px;
          line-height: 44px;
          background: #fff;
          border-bottom: 1px solid #f1f1f1;
          padding: 0 10px;
          box-sizing: border-box;
          margin-bottom: 20px;
          width: 878px;
          margin-left: -20px;
          span {
            border-left: 3px solid #ff2e54;
            padding: 2px 0 0 10px;
          }
        }
      }
    }
    .right-container {
      margin-left: 20px;
    }
  }
}
</style>
